.sidebar {
    width: 15%;
    position: fixed;
    height: 100%;
    border-right: 2px solid #e5e5e5;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .logo-container {
    margin-bottom: 4rem;
  }
  
  .logo-container h1 {
    margin-top: 1.3rem;
  }