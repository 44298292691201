* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  
}

a {
  text-decoration: none;
  color: rgb(97, 97, 97);
}

li {
  list-style: none;
}

h1 {
  animation-duration: 8s;
  animation-name: slidein;
  animation-iteration-count: infinite;
}

@keyframes slidein {
0% {
  margin-left: 0%;
}
50% {
  margin-left: 300px;
}
100% {
  margin-left: 0%;
}
}
