.recommended-flex {
    display: flex;
    margin-left: 20rem;
    
  }
  
  .recommended-title {
    margin-left: 20rem;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 20px;
    font-family: sans-serif;
  }

  .btns {
    padding: 10px 20px;
    margin-right: 6px;
    background: transparent;
    border: none;
    border: 0.6px solid #ccc;
    border-radius: 5px;
    color: #323232;
    cursor: pointer;
  }